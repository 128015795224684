<template>
  <b-row class="quiz-first">
    <b-col lg="4" class="">
      <div class="w-100">
        <!-- <div title-tag="h2" class="font-weight-bold"> -->
        <h3 class="quiz-first__title">Vamos começar!</h3>
        <!-- </div> -->
        <p class="mt-3 quiz-first__paragraph">
          Primeiramente seja muito bem vindo a nossa plataforma, nesse momento
          você irá complementar o seu cadastro respondendo algumas perguntas.
          Dessa forma iremos traçar o seu tipo de perfil de investidor e apoiar
          melhor suas escolhas de investimentos no futuro.
        </p>
      </div>
    </b-col>
    <b-col lg="12" class="mt-4 quiz-first__buttons-container">
      <b-button
        type="submit"
        variant="primary"
        class="mr-4"
        :to="{ name: 'quiz' }">
        Começar
      </b-button>
      <b-link class="fill-in-later" @click="answerLaterQuiz">
        <span>Preencher depois</span>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
// import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  // BButton,
  BRow,
  BCol,
  BButton,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataUser: {
        id: localStorage.getItem('id'),
        name: '',
      },
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    localStorage.setItem('quiz', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },

  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.teste_wizard()
    },
    check() {
      return 'check'
    },
    status() {
      return 'status'
    },
    teste_wizard() {},
    // eslint-disable-next-line consistent-return
    getDataQuiz() {
      const json = this.dataQuiz
      this.findData()
      if (json.length > 0) {
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const key in json) {
          // eslint-disable-next-line no-prototype-builtins
          if (json.hasOwnProperty(key)) {
            const data = json[key]
            setTimeout(() => this.alterData(data), 500)
          }
        }
        return true
      }
    },
    async findData() {
      const response = await this.$http.get(
        `/api/services/app/UserInvestorProfiles/GetAllForUserId?UserId=${this.dataUser.id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      if (response.data.result.items.length > 0) {
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const key in response.data.result.items) {
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.result.items.hasOwnProperty(key)) {
            const data = response.data.result.items
            // eslint-disable-next-line guard-for-in,no-shadow,no-restricted-syntax
            for (const k in data) {
              const r = data[k]
              const f = r.userInvestorProfile.id
              this.deleteData(f)
            }
          }
        }
      }
      return response.data.result.items
    },
    async deleteData(id) {
      await this.$http.delete(
        `/api/services/app/UserInvestorProfiles/Delete?Id=${id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
    },
    async answerLaterQuiz() {
      await this.$http.put(
        `/api/services/app/User/AnswerQuizLater?userId=${this.dataUser.id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      this.$router.push({ name: 'dashboard' })
    },
    async alterData(data) {
      try {
        const response = await this.$http.post(
          '/api/services/app/UserInvestorProfiles/CreateOrEdit',
          data,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )

        // eslint-disable-next-line no-undef
        if (response) {
          this.msgSuccess()
        }
      } catch (error) {
        this.msgSuccess()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados gravados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import './sass/_quiz.scss';
a.fill-in-later {
  color: #5e5873;
}
</style>
